import logger from "../../../utils/logger";
import ApiClient from "../client";

/**
 * The baseURL in the config is temporary until
 * we move all endpoints over to v6, and we can set
 * the baseURL in the axios instances which is in
 * the /client/client.js file
 */

const Facilities = {
  namespace: "facilities",
  basePath: "/v6/facilities",

  async findById(id) {
    const endpoint = `${this.basePath}/facility/${id}`;

    return ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
    }).then((resp) => {
      const returnResponse = resp.data.result || resp.data.facility;

      if (!returnResponse) {
        logger.warn(`API|Facilities: no facility found with id ${id}`);
        return null;
      }

      return returnResponse;
    });
  },

  async getAllFacilities(id) {
    const endpoint = `${this.basePath}`;
    return ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Facilities: no facility found with id ${id}`);
        return null;
      }
      return resp.data.response.facilities;
    });
  },

  async getFacilities(facilityIds = []) {
    const endpoint = `/v6.1/facilities/?ids=` + facilityIds.join(",");

    const response = await ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
    });

    if (!response?.data?.facilities) {
      logger.warn(`API|Facilities: no facilities found for ids ${facilityIds}`);

      return [];
    }

    return response.data.facilities;
  },

  async findByLatLon(lat, lon, { radius = 50, limit = 20 } = {}) {
    const endpoint = `${this.basePath}/nearbyclub`;
    const data = {
      latitude: lat,
      longitude: lon,
      radius,
      numberFacilities: limit,
    };
    return ApiClient._post(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
      data,
    }).then((resp) => {
      if (!resp.data) {
        logger.warn(`API|Facilities Nearby: no clubs nearby ${lat}, ${lon}`);
        return [];
      }

      return resp.data.facilityIds;
    });
  },

  async getFacilityByRegion(regionName, isCorpJoin = false) {
    const endpoint = `${
      this.basePath
    }/region/facility?regionName=${regionName}${
      isCorpJoin ? `&source=Web` : ""
    }`;
    return ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
    }).then((resp) => {
      if (!resp || resp.data.facilityCount < 1) {
        logger.warn(
          `API|Facilities: no facility found with region name ${regionName}`
        );
        return null;
      }
      return resp.data;
    });
  },

  async findAllWithAlias() {
    const endpoint = `${this.basePath}`;
    return ApiClient._get(endpoint, {
      baseURL: ApiClient.config().equinox_api.v6_api_host,
    }).then((resp) => {
      if (!resp.data.response.facilities) {
        logger.warn(
          `API|Facilities: Something went wrong while fetching facilities`
        );
        return null;
      }
      const facilities = [];
      resp.data.response.facilities.forEach((item) => {
        facilities.push({
          Name: item.name.toLowerCase(),
          Id: item.facilityId,
          WebName: item.webName.toLowerCase(),
          UrlName: item.urlName.toLowerCase(),
          ShortName: item.shortName.toLowerCase(),
          MobileName: item.mobileName.toLowerCase(),
          Region: item.region.toLowerCase(),
        });
      });
      return facilities;
    });
  },
};

export default Facilities;
