import { REGEX } from "@/constants";

/**
 *
 */
export const getFacilityDetailsData = async (facilityId) => {
  if (!REGEX.FACILITY_ID.test(facilityId)) {
    console.error("getExtendedFacilityData() expects a valid facilityId");
    return null;
  }

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_EQUINOX_API_HOST}/v6/facilities/facility/${facilityId}`
  );

  if (response.ok) {
    const data = await response.json();
    return data?.facility;
  }

  console.error(
    `getExtendedFacilityData() encountered an error with facilityId, ${facilityId}`,
    response.status
  );
  return null;
};

/**
 *
 */
export const getFacilitiesData = async (facilityIds) => {
  if (!Array.isArray(facilityIds)) {
    console.error("getFacilitiesData() expects an array of facilityIds");
    return [];
  }

  const response = await fetch(
    `/api/cms/facilities?facilityIds=${facilityIds.join(",")}`
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  }

  console.error("getFacilitiesData() encountered an error:", response.status);
  return [];
};

/**
 *
 */
export const getFacilityData = async (facilityId) => {
  if (!REGEX.FACILITY_ID.test(facilityId)) {
    console.error("getFacilityData() expects a valid facilityId");
    return null;
  }

  return (await getFacilitiesData([facilityId]))?.[0] || null;
};
